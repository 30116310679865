import type { FC } from 'react';

import { useState } from 'react';
import { Box, Typography, Alert } from '@mui/material';
import { format } from 'date-fns';
import useMaintenance from '../lib/hooks/useMaintenance';

const MaintenanceBanner: FC = () => {
  const [isDismissed, setIsDismissed] = useState(false);
  const { shouldShowMaintenance, startDate, endDate } = useMaintenance();

  if (!shouldShowMaintenance) return <></>;

  const formattedStartTime = format(startDate, 'PPPp');
  const formattedEndTime = format(endDate, 'PPPp');

  return (
    <Box sx={{ width: '100%', display: isDismissed ? 'none' : 'block' }}>
      <Alert sx={{ borderRadius: '0px' }} severity="info" variant="filled" onClose={() => setIsDismissed(true)}>
        <Typography>
          We will be performing scheduled maintenance from <strong>{formattedStartTime}</strong> to{' '}
          <strong>{formattedEndTime}</strong>. During this time, some services may be unavailable.
        </Typography>
      </Alert>
    </Box>
  );
};

export default MaintenanceBanner;
