import { useQuery } from '@tanstack/react-query';
import { isAfter } from 'date-fns';
import axios from 'axios';

type UseMaintenance = () =>
  | {
      startDate: Date;
      endDate: Date;
      shouldShowMaintenance: true;
    }
  | {
      startDate?: never;
      endDate?: never;
      shouldShowMaintenance: false;
    };

const useMaintenance: UseMaintenance = () => {
  const fetchMaintenanceData = async () => {
    const response = await axios.get('/maintenance.json');
    return response.data;
  };

  const { data } = useQuery(['maintenanceData'], fetchMaintenanceData, {
    refetchInterval: 60000,
  });

  let startDate: Date | undefined;
  let endDate: Date | undefined;

  try {
    if (data?.start) startDate = new Date(data.start);

    if (data?.end) endDate = new Date(data.end);
  } catch (error) {
    // eslint-disable-line @typescript-eslint/no-unused-vars
    // Prevent bad date from crashing hook
  }

  if (startDate && endDate && isAfter(startDate, new Date())) {
    return { startDate, endDate, shouldShowMaintenance: true };
  }

  return { shouldShowMaintenance: false };
};

export default useMaintenance;
